/* eslint-disable react/jsx-newline */

/* eslint-disable @typescript-eslint/no-explicit-any */
import * as React from 'react'

import imgDesktopPlanning from '@assets/images/desktop-planning.png'
import imgMobilePlanning from '@assets/images/mobile-planning.png'
import gradients from '@assets/svg/gradients-center.svg'
import { Box, Center, Flex, Heading, Icon, Image, Text, useMediaQuery } from '@chakra-ui/react'
import Seo from '@components/Seo'
import Section from '@components/core/Section'
import { CtaButton } from '@components/ui/Button'
import { Card, GradientCard, HorizontalGridCard } from '@components/ui/Card'
import { HorizontalCarousel, VerticalCarousel } from '@components/ui/Carousel'
import { MobileDesktopDisplay } from '@components/ui/Display'
import Flow from '@components/ui/Flow'
import IconItemList from '@components/ui/IconItemList'
import IconLink from '@components/ui/IconLink'
import MenuSticky from '@components/ui/MenuSticky'
import Quote from '@components/ui/Quote'
import { features, functionalities, horizontalCarouselContent, verticalCarouselContent } from '@data/laboratoires'
import { graphql, navigate } from 'gatsby'
import { AiOutlineArrowDown } from 'react-icons/ai'
import { FaLongArrowAltRight } from 'react-icons/fa'
import { GrCheckmark } from 'react-icons/gr'
import { v4 as uuidv4 } from 'uuid'

const LaboratoriesPage = () => {
    const [isTablet, isCardsColumn, isDesktop] = useMediaQuery([
        '(min-width: 1200px)',
        '(max-width: 1431px)',
        '(min-width: 1600px)',
    ])

    return (
        <>
            <Seo
                title="Logiciel Planning Laboratoire"
                description="Découvrez Staffea pour la gestion de vos plannings en laboratoire ➜ ✓ Installation rapide ✓ Création en 2H ✓ Gestion en temps réel ➜ Demandez une démo !"
            />

            <main>
                <MenuSticky>
                    <Section bg="indigo.100" id="laboratories-staffea-section" name="staffea">
                        <Flex
                            pt={20}
                            pb={16}
                            gap={8}
                            align="center"
                            justify={['center', 'center', 'space-between']}
                            wrap="wrap"
                            maxW="1200px"
                            mx="auto"
                        >
                            <Flex
                                direction="column"
                                align="center"
                                mx="auto"
                                sx={{
                                    '@media (min-width: 1440px)': {
                                        maxW: '50%',
                                        marginX: 'revert',
                                        alignItems: 'start',
                                    },
                                }}
                            >
                                <Heading
                                    as="h1"
                                    fontWeight="bold"
                                    color="indigo.500"
                                    textAlign={['center', 'center', 'start']}
                                    mb={10}
                                >
                                    Logiciel de planning pour laboratoires
                                </Heading>

                                <IconItemList list={features} icon={GrCheckmark} align="start" />

                                <Flex direction="column" w={['100%', '100%', '50%']} pt="12">
                                    <CtaButton
                                        text="Demander une démo"
                                        description="Démonstration de 30 minutes en visioconférence"
                                        onClick={() => navigate('/contact')}
                                    />
                                </Flex>
                            </Flex>

                            <Box
                                mx="auto"
                                sx={{
                                    '@media (min-width: 1440px)': {
                                        marginX: 'revert',
                                    },
                                }}
                            >
                                <MobileDesktopDisplay
                                    mobileContent={{
                                        image: (
                                            <Image
                                                src={imgMobilePlanning}
                                                alt="Staffea, application mobile écran de consultation du planning individuel"
                                            />
                                        ),
                                        description: 'A chaque salarié, son planning individuel !',
                                    }}
                                    desktopContent={{
                                        image: (
                                            <Image
                                                src={imgDesktopPlanning}
                                                alt="Staffea, application web écran de gestion du planning des managers"
                                            />
                                        ),
                                        description: 'Votre interface de gestion des plannings.',
                                    }}
                                />
                            </Box>
                        </Flex>
                    </Section>

                    <Section bg="white" id="laboratories-interoperability-section" name="interopérabilité">
                        <Box py={20}>
                            <GradientCard maxW="800px">
                                <Flex direction="column" alignItems="center" justifyContent="center" color="white">
                                    <Text as="h2" fontSize="lg" fontWeight="bold" align="center" mb={6}>
                                        Avec Staffea, pas d&#39;installation informatique complexe à prévoir.
                                        <br /> Notre solution se connecte à tous les logiciels RH !
                                    </Text>

                                    <Text fontSize="sm" align="center" px={[0, 0, 20]} mb={8}>
                                        Staffea est une solution 100% web et mobile, elle est donc accessible facilement
                                        depuis chaque centre de prélèvement ou depuis votre plateau technique
                                        d&#39;analyse. Un logiciel pratique pour les laboratoires multi-sites ! Il
                                        s&#39;interface directement à vos logiciels métiers et solutions RH en place.
                                    </Text>

                                    <IconLink
                                        text="Voir le process d'intégration"
                                        to={`#laboratories-onboarding-section`}
                                        icon={AiOutlineArrowDown}
                                        color="white"
                                        hoverColor="green.500"
                                    />
                                </Flex>
                            </GradientCard>
                        </Box>
                    </Section>

                    <Section bg="indigo.100" id="laboratories-mobile-app-section" name="app mobile">
                        <Box py={20}>
                            <Text as="h2" fontSize="xl" fontWeight="bold" color="indigo.500" mb={10}>
                                Une application mobile dédiée aux collaborateurs
                                <Text as="span" color="green.500" display="block">
                                    pour gagner en réactivité.
                                </Text>
                            </Text>

                            <VerticalCarousel
                                contents={verticalCarouselContent}
                                footer={
                                    <CtaButton
                                        text="Demander une démo"
                                        description="Démonstration de 30 minutes en visioconférence"
                                        onClick={() => navigate('/contact')}
                                        height="14"
                                    />
                                }
                            />
                        </Box>
                    </Section>

                    <Section bg="white" id="laboratories-features-section" name="fonctionnalités">
                        <Box py={20}>
                            <Text as="h2" fontSize="xl" fontWeight="bold" color="indigo.500" mb={10}>
                                Des fonctionnalités de planning précieuses
                                <Text as="span" color="green.500" display="block">
                                    pour économiser du temps.
                                </Text>
                            </Text>

                            <Flex my="12" gap="8" wrap="wrap" mx="auto" maxW={isCardsColumn ? 'none' : '1002px'}>
                                {functionalities.map(({ id, media, title, content }, index) => (
                                    <HorizontalGridCard
                                        minH="170"
                                        key={id}
                                        media={media}
                                        title={title}
                                        content={content}
                                        maxW="485px"
                                        mx={index === functionalities.length - 1 && !isCardsColumn ? 'none' : 'auto'}
                                        imagePadding={isDesktop ? '25' : '5'}
                                    />
                                ))}
                            </Flex>

                            <Text as="h2" fontSize="xl" fontWeight="bold" color="indigo.500" py={10}>
                                Les points forts de Staffea
                                <Text as="span" color="green.500" display="block">
                                    pour séréniser la gestion de planning en laboratoire.
                                </Text>
                            </Text>

                            <HorizontalCarousel
                                content={horizontalCarouselContent.map(({ title, description, image, alt }) => {
                                    return {
                                        title,
                                        description: (
                                            <>
                                                <Text color="indigo.500" fontSize="xs" align="start">
                                                    {description}
                                                </Text>
                                            </>
                                        ),
                                        image: <Image key={uuidv4()} src={image} alt={alt} />,
                                    }
                                })}
                            />
                        </Box>
                    </Section>

                    <Section
                        id="laboratories-onboarding-section"
                        name="onboarding"
                        backgroundImage={gradients}
                        footer={
                            <Section bg="indigo.500">
                                <Flex
                                    w="fit-content"
                                    mx="auto"
                                    py="6"
                                    gap={6}
                                    align="center"
                                    direction={['column', 'column', 'row']}
                                >
                                    <Text as="span" variant="inline" color="white" fontSize="sm" align="center">
                                        Staffea réduit <Text variant="bold">drastiquement</Text> le temps de saisie et
                                        de modification des plannings de vos équipes.
                                    </Text>

                                    <Icon
                                        as={FaLongArrowAltRight}
                                        color="white"
                                        w="6"
                                        h="6"
                                        display={['none', 'none', 'block']}
                                    />

                                    <CtaButton
                                        onClick={() => navigate('/contact')}
                                        text="Réserver une démo !"
                                        width="200px"
                                        hoverColor="green.500"
                                    />
                                </Flex>
                            </Section>
                        }
                    >
                        <Box py={20}>
                            <Text as="h2" fontSize="xl" fontWeight="bold" color="indigo.500" mb={4}>
                                Un accompagnement technique
                                <Text as="span" color="green.500" display="block">
                                    pour vous aider à déployer Staffea dans votre laboratoire.
                                </Text>
                            </Text>

                            <Flow>
                                <Card size="xs" align="center">
                                    <Text
                                        as="h3"
                                        color="indigo.500"
                                        fontWeight="bold"
                                        mb="4"
                                        fontSize={isTablet && !isDesktop ? 'sm' : 'md'}
                                    >
                                        Récupération de vos données
                                    </Text>

                                    <Text color="indigo.500" mb="4" fontSize={isTablet && !isDesktop ? 'xs' : 'md'}>
                                        Pour plus d&#39;efficacité, téléchargez un fichier d&#39;import ou interfacez
                                        Staffea avec votre logiciel métier afin de récupérer les données de vos
                                        collaborateurs et du laboratoire.
                                    </Text>
                                </Card>

                                <Card size="xs" align="center">
                                    <Text
                                        as="h3"
                                        color="indigo.500"
                                        fontWeight="bold"
                                        mb="4"
                                        fontSize={isTablet && !isDesktop ? 'sm' : 'md'}
                                    >
                                        Configuration du planning
                                    </Text>

                                    <Text color="indigo.500" mb="4" fontSize={isTablet && !isDesktop ? 'xs' : 'md'}>
                                        2h suffisent pour construire vos trames de plannings et vos roulements. Utilisez
                                        la suggestion automatique basée sur l&#39;IA pour les attribuer à votre équipe.
                                        Si votre planning n&#39;est pas conforme, le logiciel vous informe
                                        instantanément.
                                    </Text>
                                </Card>

                                <Card size="xs" align="center">
                                    <Text
                                        as="h3"
                                        color="indigo.500"
                                        fontWeight="bold"
                                        mb="4"
                                        fontSize={isTablet && !isDesktop ? 'sm' : 'md'}
                                    >
                                        Formation au logiciel
                                    </Text>

                                    <Text color="indigo.500" mb="4" fontSize={isTablet && !isDesktop ? 'xs' : 'md'}>
                                        Vos planning managers reçoivent 2 jours de formation pour devenir autonome dans
                                        l&#39;utilisation du logiciel Staffea. En cas de nécessité, un support
                                        utilisateur vous est fourni à tout moment.
                                    </Text>
                                </Card>

                                <Card size="xs" align="center">
                                    <Text
                                        as="h3"
                                        color="indigo.500"
                                        fontWeight="bold"
                                        mb="4"
                                        fontSize={isTablet && !isDesktop ? 'sm' : 'md'}
                                    >
                                        Mise en service
                                    </Text>

                                    <Text color="indigo.500" mb="4" fontSize={isTablet && !isDesktop ? 'xs' : 'md'}>
                                        En moins de 48h, vous pourrez commencer à utiliser Staffea. Le déploiement est
                                        réalisé à distance par notre équipe technique. Le logiciel est accessible avec
                                        un accès internet, tout simplement.
                                    </Text>
                                </Card>
                            </Flow>
                        </Box>
                    </Section>

                    <Section
                        bg="indigo.100"
                        id="laboratories-mission-section"
                        name="mission"
                        footer={
                            <Section bg="white">
                                <Box py={20}>
                                    <Center textAlign="center">
                                        <Text as="h2" fontSize="xl" fontWeight="bold" color="indigo.500" mb={10}>
                                            Plus qu&#39;un simple logiciel de planning,
                                            <Text as="span" color="green.500" display="block">
                                                Staffea prend soin de vos équipes&nbsp;!
                                            </Text>
                                        </Text>
                                    </Center>
                                    <Box mb="16">
                                        <Quote
                                            content="Pour accroître la qualité des analyses et la satisfaction des patients de votre laboratoire, une planification soigneuse est indispensable pour le bien-être de vos équipes.

                                            Avec Staffea, vous développez une démarche QVCT, centrée sur le bien-être de votre personnel de laboratoire, tout en assurant la continuité de l'activité malgré les arrêts maladies, les absences et les congés. 
                                            
                                            Vos collaborateurs sont protégés de la sur sollicitation grâce à leurs indisponibilités partagées sur Staffea. Ils bénéficient aussi d'un droit à la déconnexion, en désactivant les notifications push durant leurs jours de repos. 
                                            
                                            Les équipes peuvent solliciter facilement des échanges avec des collègues, en quelques clics sur l'application. Trouver des remplaçants est également bien plus aisé avec Staffea !"
                                            author="Mathieu MOSQUET - Cofondateur de Staffea"
                                            nbCol={2}
                                        />
                                    </Box>
                                </Box>
                            </Section>
                        }
                    >
                        <Box py={20}>
                            <Center textAlign="center">
                                <Text as="h2" fontSize="xl" fontWeight="bold" color="indigo.500" mb={10}>
                                    Une solution adaptée
                                    <Text as="span" color="green.500" display="block">
                                        à toutes les professions en laboratoire.
                                    </Text>
                                </Text>
                            </Center>

                            <Text color="indigo.500" mb={16}>
                                Développez la qualité de vie au travail de vos techniciens de laboratoire, laborantins,
                                responsables de laboratoire, infirmiers et secrétaires en intégrant le logiciel de
                                planning pour laboratoire Staffea. Un planning organisé rigoureusement et en fonction de
                                leurs préférences améliorera leur bien-être et leur sérénité.
                                <br />
                                <br />
                                Staffea est la meilleure solution pour organiser les horaires de vos équipes, sur un ou
                                plusieurs sites, avec l&#39;appui de l&#39;intelligence artificielle et du machine
                                learning. Vos plannings sont créés en 2H seulement : vous n&#39;avez qu&#39;à vous fier
                                au logiciel pour leur attribution et leur conformité.
                            </Text>
                        </Box>
                    </Section>
                </MenuSticky>
            </main>
        </>
    )
}

export default LaboratoriesPage

export const query = graphql`
    query ($language: String!) {
        locales: allLocale(filter: { ns: { in: ["ehpad", "404"] }, language: { eq: $language } }) {
            edges {
                node {
                    ns
                    data
                    language
                }
            }
        }
    }
`
